"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Country = void 0;
//obtained from https://stefangabos.github.io/world_countries/
var Country;
(function (Country) {
    Country["AFGHANISTAN"] = "afg";
    Country["ALAND_ISLANDS"] = "ala";
    Country["ALBANIA"] = "alb";
    Country["ALGERIA"] = "dza";
    Country["AMERICAN_SAMOA"] = "asm";
    Country["ANDORRA"] = "and";
    Country["ANGOLA"] = "ago";
    Country["ANGUILLA"] = "aia";
    Country["ANTARCTICA"] = "ata";
    Country["ANTIGUA_AND_BARBUDA"] = "atg";
    Country["ARGENTINA"] = "arg";
    Country["ARMENIA"] = "arm";
    Country["ARUBA"] = "abw";
    Country["AUSTRALIA"] = "aus";
    Country["AUSTRIA"] = "aut";
    Country["AZERBAIJAN"] = "aze";
    Country["BAHAMAS"] = "bhs";
    Country["BAHRAIN"] = "bhr";
    Country["BANGLADESH"] = "bgd";
    Country["BARBADOS"] = "brb";
    Country["BELARUS"] = "blr";
    Country["BELGIUM"] = "bel";
    Country["BELIZE"] = "blz";
    Country["BENIN"] = "ben";
    Country["BERMUDA"] = "bmu";
    Country["BHUTAN"] = "btn";
    Country["BOLIVIA"] = "bol";
    Country["BONAIRE_SINT_EUSTATIUS_AND_SABA"] = "bes";
    Country["BOSNIA_AND_HERZEGOVINA"] = "bih";
    Country["BOTSWANA"] = "bwa";
    Country["BOUVET_ISLAND"] = "bvt";
    Country["BRAZIL"] = "bra";
    Country["BRITISH_INDIAN_OCEAN_TERRITORY"] = "iot";
    Country["BRUNEI_DARUSSALAM"] = "brn";
    Country["BULGARIA"] = "bgr";
    Country["BURKINA_FASO"] = "bfa";
    Country["BURUNDI"] = "bdi";
    Country["CABO_VERDE"] = "cpv";
    Country["CAMBODIA"] = "khm";
    Country["CAMEROON"] = "cmr";
    Country["CANADA"] = "can";
    Country["CAYMAN_ISLANDS"] = "cym";
    Country["CENTRAL_AFRICAN_REPUBLIC"] = "caf";
    Country["CHAD"] = "tcd";
    Country["CHILE"] = "chl";
    Country["CHINA"] = "chn";
    Country["CHRISTMAS_ISLAND"] = "cxr";
    Country["COCOS_ISLANDS"] = "cck";
    Country["COLOMBIA"] = "col";
    Country["COMOROS"] = "com";
    Country["CONGO"] = "cog";
    Country["COOK_ISLANDS"] = "cok";
    Country["COSTA_RICA"] = "cri";
    Country["COTE_DIVOIRE"] = "civ";
    Country["CROATIA"] = "hrv";
    Country["CUBA"] = "cub";
    Country["CURACAO"] = "cuw";
    Country["CYPRUS"] = "cyp";
    Country["CZECHIA"] = "cze";
    Country["DEMOCRATIC_REPUBLIC_OF_THE_CONGO"] = "cod";
    Country["DENMARK"] = "dnk";
    Country["DJIBOUTI"] = "dji";
    Country["DOMINICA"] = "dma";
    Country["DOMINICAN_REPUBLIC"] = "dom";
    Country["ECUADOR"] = "ecu";
    Country["EGYPT"] = "egy";
    Country["EL_SALVADOR"] = "slv";
    Country["EQUATORIAL_GUINEA"] = "gnq";
    Country["ERITREA"] = "eri";
    Country["ESTONIA"] = "est";
    Country["ESWATINI"] = "swz";
    Country["ETHIOPIA"] = "eth";
    Country["FALKLAND_ISLANDS"] = "flk";
    Country["FAROE_ISLANDS"] = "fro";
    Country["FIJI"] = "fji";
    Country["FINLAND"] = "fin";
    Country["FRANCE"] = "fra";
    Country["FRENCH_GUIANA"] = "guf";
    Country["FRENCH_POLYNESIA"] = "pyf";
    Country["FRENCH_SOUTHERN_TERRITORIES"] = "atf";
    Country["GABON"] = "gab";
    Country["GAMBIA"] = "gmb";
    Country["GEORGIA"] = "geo";
    Country["GERMANY"] = "deu";
    Country["GHANA"] = "gha";
    Country["GIBRALTAR"] = "gib";
    Country["GREECE"] = "grc";
    Country["GREENLAND"] = "grl";
    Country["GRENADA"] = "grd";
    Country["GUADELOUPE"] = "glp";
    Country["GUAM"] = "gum";
    Country["GUATEMALA"] = "gtm";
    Country["GUERNSEY"] = "ggy";
    Country["GUINEA"] = "gin";
    Country["GUINEA_BISSAU"] = "gnb";
    Country["GUYANA"] = "guy";
    Country["HAITI"] = "hti";
    Country["HEARD_ISLAND_AND_MCDONALD_ISLANDS"] = "hmd";
    Country["HOLY_SEE"] = "vat";
    Country["HONDURAS"] = "hnd";
    Country["HONG_KONG"] = "hkg";
    Country["HUNGARY"] = "hun";
    Country["ICELAND"] = "isl";
    Country["INDIA"] = "ind";
    Country["INDONESIA"] = "idn";
    Country["IRAN"] = "irn";
    Country["IRAQ"] = "irq";
    Country["IRELAND"] = "irl";
    Country["ISLE_OF_MAN"] = "imn";
    Country["ISRAEL"] = "isr";
    Country["ITALY"] = "ita";
    Country["JAMAICA"] = "jam";
    Country["JAPAN"] = "jpn";
    Country["JERSEY"] = "jey";
    Country["JORDAN"] = "jor";
    Country["KAZAKHSTAN"] = "kaz";
    Country["KENYA"] = "ken";
    Country["KIRIBATI"] = "kir";
    Country["NORTH_KOREA"] = "prk";
    Country["SOUTH_KOREA"] = "kor";
    Country["KUWAIT"] = "kwt";
    Country["KYRGYZSTAN"] = "kgz";
    Country["LAO_PEOPLES_DEMOCRATIC_REPUBLIC"] = "lao";
    Country["LATVIA"] = "lva";
    Country["LEBANON"] = "lbn";
    Country["LESOTHO"] = "lso";
    Country["LIBERIA"] = "lbr";
    Country["LIBYA"] = "lby";
    Country["LIECHTENSTEIN"] = "lie";
    Country["LITHUANIA"] = "ltu";
    Country["LUXEMBOURG"] = "lux";
    Country["MACAO"] = "mac";
    Country["MADAGASCAR"] = "mdg";
    Country["MALAWI"] = "mwi";
    Country["MALAYSIA"] = "mys";
    Country["MALDIVES"] = "mdv";
    Country["MALI"] = "mli";
    Country["MALTA"] = "mlt";
    Country["MARSHALL_ISLANDS"] = "mhl";
    Country["MARTINIQUE"] = "mtq";
    Country["MAURITANIA"] = "mrt";
    Country["MAURITIUS"] = "mus";
    Country["MAYOTTE"] = "myt";
    Country["MEXICO"] = "mex";
    Country["FEDERATED_STATES_OF_MICRONESIA"] = "fsm";
    Country["MOLDOVA"] = "mda";
    Country["MONACO"] = "mco";
    Country["MONGOLIA"] = "mng";
    Country["MONTENEGRO"] = "mne";
    Country["MONTSERRAT"] = "msr";
    Country["MOROCCO"] = "mar";
    Country["MOZAMBIQUE"] = "moz";
    Country["MYANMAR"] = "mmr";
    Country["NAMIBIA"] = "nam";
    Country["NAURU"] = "nru";
    Country["NEPAL"] = "npl";
    Country["NETHERLANDS"] = "nld";
    Country["NEW_CALEDONIA"] = "ncl";
    Country["NEW_ZEALAND"] = "nzl";
    Country["NICARAGUA"] = "nic";
    Country["NIGER"] = "ner";
    Country["NIGERIA"] = "nga";
    Country["NIUE"] = "niu";
    Country["NORFOLK_ISLAND"] = "nfk";
    Country["NORTH_MACEDONIA"] = "mkd";
    Country["NORTHERN_MARIANA_ISLANDS"] = "mnp";
    Country["NORWAY"] = "nor";
    Country["OMAN"] = "omn";
    Country["PAKISTAN"] = "pak";
    Country["PALAU"] = "plw";
    Country["STATE_OF_PALESTINE"] = "pse";
    Country["PANAMA"] = "pan";
    Country["PAPUA_NEW_GUINEA"] = "png";
    Country["PARAGUAY"] = "pry";
    Country["PERU"] = "per";
    Country["PHILIPPINES"] = "phl";
    Country["PITCAIRN"] = "pcn";
    Country["POLAND"] = "pol";
    Country["PORTUGAL"] = "prt";
    Country["PUERTO_RICO"] = "pri";
    Country["QATAR"] = "qat";
    Country["REUNION"] = "reu";
    Country["ROMANIA"] = "rou";
    Country["RUSSIAN_FEDERATION"] = "rus";
    Country["RWANDA"] = "rwa";
    Country["SAINT_BARTHELEMY"] = "blm";
    Country["SAINT_HELENA"] = "shn";
    Country["SAINT_KITTS_AND_NEVIS"] = "kna";
    Country["SAINT_LUCIA"] = "lca";
    Country["SAINT_MARTIN"] = "maf";
    Country["SAINT_PIERRE_AND_MIQUELON"] = "spm";
    Country["SAINT_VINCENT_AND_THE_GRENADINES"] = "vct";
    Country["SAMOA"] = "wsm";
    Country["SAN_MARINO"] = "smr";
    Country["SAO_TOME_AND_PRINCIPE"] = "stp";
    Country["SAUDI_ARABIA"] = "sau";
    Country["SENEGAL"] = "sen";
    Country["SERBIA"] = "srb";
    Country["SEYCHELLES"] = "syc";
    Country["SIERRA_LEONE"] = "sle";
    Country["SINGAPORE"] = "sgp";
    Country["SINT_MAARTEN"] = "sxm";
    Country["SLOVAKIA"] = "svk";
    Country["SLOVENIA"] = "svn";
    Country["SOLOMON_ISLANDS"] = "slb";
    Country["SOMALIA"] = "som";
    Country["SOUTH_AFRICA"] = "zaf";
    Country["SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS"] = "sgs";
    Country["SOUTH_SUDAN"] = "ssd";
    Country["SPAIN"] = "esp";
    Country["SRI_LANKA"] = "lka";
    Country["SUDAN"] = "sdn";
    Country["SURINAME"] = "sur";
    Country["SVALBARD_AND_JAN_MAYEN"] = "sjm";
    Country["SWEDEN"] = "swe";
    Country["SWITZERLAND"] = "che";
    Country["SYRIAN_ARAB_REPUBLIC"] = "syr";
    Country["TAIWAN"] = "twn";
    Country["TAJIKISTAN"] = "tjk";
    Country["TANZANIA"] = "tza";
    Country["THAILAND"] = "tha";
    Country["TIMOR_LESTE"] = "tls";
    Country["TOGO"] = "tgo";
    Country["TOKELAU"] = "tkl";
    Country["TONGA"] = "ton";
    Country["TRINIDAD_AND_TOBAGO"] = "tto";
    Country["TUNISIA"] = "tun";
    Country["T\u00DCRKIYE"] = "tur";
    Country["TURKMENISTAN"] = "tkm";
    Country["TURKS_AND_CAICOS_ISLANDS"] = "tca";
    Country["TUVALU"] = "tuv";
    Country["UGANDA"] = "uga";
    Country["UKRAINE"] = "ukr";
    Country["UNITED_ARAB_EMIRATES"] = "are";
    Country["UNITED_KINGDOM_OF_GREAT_BRITAIN_AND_NORTHERN_IRELAND"] = "gbr";
    Country["UNITED_STATES_OF_AMERICA"] = "usa";
    Country["UNITED_STATES_MINOR_OUTLYING_ISLANDS"] = "umi";
    Country["URUGUAY"] = "ury";
    Country["UZBEKISTAN"] = "uzb";
    Country["VANUATU"] = "vut";
    Country["VENEZUELA"] = "ven";
    Country["VIET_NAM"] = "vnm";
    Country["VIRGIN_ISLANDS_BRITISH"] = "vgb";
    Country["VIRGIN_ISLANDS_US"] = "vir";
    Country["WALLIS_AND_FUTUNA"] = "wlf";
    Country["WESTERN_SAHARA"] = "esh";
    Country["YEMEN"] = "yem";
    Country["ZAMBIA"] = "zmb";
    Country["ZIMBABWE"] = "zwe";
})(Country || (exports.Country = Country = {}));
