import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Country as CountryEnum } from "shared/model/countries";

import { BasicWithButton } from "./fragments/basic-with-button";
import { SearchableSelect } from "../../../../../input/searchable-select";
import { OnMessageSendFunction } from "../conversation";
import { ChatOptionComponentProps } from "../lookup";

export const CountryDropdown: React.FC<ChatOptionComponentProps> = ({
  sendResponse,
}) => {
  const { t } = useTranslation();

  const countryOptions = Object.entries(CountryEnum).map(([key, value]) => ({
    key,
    value: t(`countries.${value}`),
  }));

  const [selectedCountry, setSelectedCountry] = useState<string | undefined>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isNeedSelection, setIsNeedSelection] = useState(false);

  const handleSubmit: OnMessageSendFunction = () => {
    if (!selectedCountry) {
      setIsNeedSelection(true);
      return { hasResponseBeenSent: false };
    }
    
    setIsSubmitted(true);
    setSelectedCountry(undefined);

    return sendResponse({
      message: selectedCountry,
      selection: [selectedCountry],
    });
  };

  return (
    <BasicWithButton sendResponse={handleSubmit} message={t("graph.text.submit")}>
      <SearchableSelect
        options={countryOptions}
        placeholder={t("graph.choice.COUNTRY.placeholder")}
        searchPlaceholder={t("graph.text.search")}
        title="country"
        disabled={isSubmitted}
        onSelect={(value) => {
          setSelectedCountry(value);
          setIsNeedSelection(false);
        }}
        showError={isNeedSelection}
        errorMessage={t("graph.text.errorMessage.selectOne")}
      />
    </BasicWithButton>
  );
};
